import React, { useEffect, useState } from "react";
import Card from "components/card";
import { useNavigate } from "react-router-dom";
import Loading from "react-fullscreen-loading";
import { toast } from "react-toastify";
import { getAuth } from "firebase/auth";
import { db } from "../../../../firebase/firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { fetchLoggedInUserDetails } from "utils/utils";

import {
  getLocalStorageValue,
  fetchUserRegulars,
  fetchRegularDetails,
  combineWorkplaceInfo,
} from "utils/utils";

import { GoogleMap, LoadScript, PlacesService } from "@react-google-maps/api";

const UpdateWorkPlace = ({ headerTitle = "", buttonText = "" }) => {
  const navigate = useNavigate();
  const [workplaces, setWorkplaces] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  // New state for users
  const user = getAuth();

  const [totalRegulars, setTotalRegulars] = useState(0);
  const [regulars, setRegulars] = useState([]);
  const [fullRegularDetails, setFullRegularDetails] = useState([]);
  const [isWorking, setIsWorking] = useState(true);
  const [workplaceName, setWorkplaceName] = useState([]);
  const userId = getLocalStorageValue("userDetails", "phoneNumber");
  const GOOGLE_PLACES_API_KEY = process.env.REACT_APP_API_KEY;

  const fetchImageForWorkplace = (workplaceName) => {
    return new Promise((resolve, reject) => {
      const service = new window.google.maps.places.PlacesService(
        document.createElement("div")
      );
      service.findPlaceFromQuery(
        {
          query: workplaceName,
          fields: ["photos"],
        },
        (results, status) => {
          if (
            status === window.google.maps.places.PlacesServiceStatus.OK &&
            results[0]
          ) {
            const photo = results[0].photos && results[0].photos[0]; // Check if photos exist
            if (photo) {
              const imageUrl = photo.getUrl({ maxWidth: 400 }); // Get the image URL
              resolve(imageUrl);
            } else {
              resolve(null); // No photo found
            }
          } else {
            resolve(null); // No image found
          }
        }
      );
    });
  };

  const fetchAndLogUserData = async () => {
    try {
      setLoading(true);
      const userRegulars = await fetchUserRegulars(userId);
      const userRegularDetails = await fetchRegularDetails(userId);
      fetchRegularDetails(userId);
      //Seteing the full reg detauls aswell
      setFullRegularDetails(userRegularDetails);
      setIsWorking(userRegularDetails.isWorking);

      setRegulars(userRegulars);
      setTotalRegulars(userRegulars.length);

      const workplacesWithImages = await Promise.all(
        userRegularDetails.workplaces.map(async (workplace) => {
          const imageUrl = await fetchImageForWorkplace(workplace.address); // Assuming workplace has a 'name' property
          return { address: workplace.address, image: imageUrl }; // Add address and image URL to workplace data
        })
      );
      setWorkplaceName(
        workplacesWithImages.map((workplace) => ({
          ...workplace,
          imageURL: workplace.image, // Append imageURL key
        }))
      );
    } catch (error) {
      console.error("Error fetching user data:", error);
      toast.error("Failed to fetch user data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAndLogUserData();
    localStorage.setItem("home", "/auth/workplace-home");
    localStorage.setItem("isWorking", isWorking);
  }, [userId, isWorking]);

  useEffect(() => {
    if (fullRegularDetails && fullRegularDetails.workplaces) {
      const workplace = fullRegularDetails.workplaces;

      setWorkplaceName(workplace);
      const combinedWorkplaceInfo = combineWorkplaceInfo(workplace);
      setWorkplaces([combinedWorkplaceInfo]);
      // console.log("workplace", workplace)

      // setWorkplaces(workplace)
      if (typeof workplace === "object" && !Array.isArray(workplace)) {
        const workPlaceAdress = workplace.address[0];
        const workPlaceName = workplace.workplace;
        setWorkplaceName(workPlaceAdress + " , " + workPlaceName);
      }
      // setWorkplaceName(`${workplace.Name}`);
    }
  }, [fullRegularDetails]);

  useEffect(() => {}, [workplaces]);



  const handleRouteClick = (page) => {
    navigate(page);
  };
  const handleRemoveWorkplaceByAddress = async (addressToSelect) => {
    const userDetails = await fetchLoggedInUserDetails(userId);
    setLoading(true);
    try {
      // Get the user's current workplaces
      const currentWorkplaces = userDetails.workplaces || [];
      
      // Find the workplace with matching address and update it
      const updatedWorkplaces = currentWorkplaces.map(workplace => {
        if (workplace.address === addressToSelect.address) {
          return {
            ...workplace,
            address: addressToSelect.address
          };
        }
        return workplace;
      });

      // Update user document with new workplaces array and currentWorkplace
      const userRef = doc(db, "users", userId);
      await updateDoc(userRef, {
        workplaces: updatedWorkplaces,
        currentWorkplace: addressToSelect.address
      });

      // Update local state
      setWorkplaceName(prevWorkplaces => 
        prevWorkplaces.map(workplace => {
          if (workplace.address === addressToSelect.address) {
            return {
              ...workplace, 
              address: addressToSelect.address
            };
          }
          return workplace;
        })
      );

      toast.success("Workplace address updated successfully");
    } catch (error) {
      console.error("Error updating workplace address:", error);
      toast.error("Failed to update workplace address");
    }
    localStorage.setItem("selectedWorkplace", addressToSelect.address);
    setTimeout(() => {
      setLoading(false);
    }, 1500);
 
  };

  useEffect(() => {
    console.log("Workplace Name", workplaceName);
  }, [workplaceName]);

  return (
    <LoadScript googleMapsApiKey={GOOGLE_PLACES_API_KEY} libraries={["places"]}>
      <Card extra={"!mt-[2rem] mb-10 !z-5 "}>
        <Loading loading={loading} loaderColor="#f97316" />
        {/* DeleteWorkplaces Header */}
        <div className="ml-3 flex items-center justify-between rounded-t-3xl p-3">
          <div className="text-lg font-bold text-navy-700 dark:text-white">
            {headerTitle}
          </div>
        </div>

        {/* History CardData */}
        {workplaces.length === 0 ? (
          <div className="pt-[100px] pb-[100px] text-center">
            <div classname="text-navy-700 dark:text-white">
              No workplaces found
            </div>
            <div classname="text-navy-700 dark:text-white">
              would you like to add one?
            </div>
          </div>
        ) : (
          <div className="list-scroll-d">
            {workplaceName.map((data, index) => (
              <div
                key={index}
                className="flex w-full items-start justify-between bg-white px-3 py-[20px] hover:shadow-2xl dark:!bg-navy-800 dark:shadow-none dark:hover:!bg-navy-700"
              >
                <div className="flex items-center gap-3">
                  <div className="flex h-16 w-16 items-center justify-center">
                    <img
                      className="h-full w-full rounded-xl"
                      src={data.image} // Assuming 'data' has an 'image' property
                      alt=""
                    />
                  </div>
                  <div className="flex w-56 flex-col">
                    <h5 className="text-base font-bold text-navy-700 dark:text-white">
                      {data.address}{" "}
                      {/* Ensure 'data' has an 'address' property */}
                    </h5>
                    <p className="mt-1 text-sm font-normal text-gray-600">
                      {data.name} {/* Ensure 'data' has a 'name' property */}
                    </p>
                  </div>
                </div>
                <div className="mt-1 ml-[-15px] flex items-center justify-center text-navy-700 dark:text-white">
                  <div>
                    <button
                      onClick={() => handleRemoveWorkplaceByAddress(data)}
                    >
                      {" "}
                      {/* Pass the correct workplace data */}
                      <svg width="1em" height="1em" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 pointer-events-none"><path d="M9.456 4.216l-5.985 7.851c-.456.637-.583 1.402-.371 2.108l.052.155a2.384 2.384 0 002.916 1.443l2.876-.864.578 4.042a2.384 2.384 0 002.36 2.047h.234l.161-.006a2.384 2.384 0 002.2-2.041l.576-4.042 2.877.864a2.384 2.384 0 002.625-3.668L14.63 4.33a3.268 3.268 0 00-5.174-.115zm3.57.613c.16.114.298.253.411.411l5.897 7.736a.884.884 0 01-.973 1.36l-3.563-1.069a.884.884 0 00-1.129.722l-.678 4.75a.884.884 0 01-.875.759h-.234a.884.884 0 01-.875-.76l-.679-4.75a.884.884 0 00-1.128-.72l-3.563 1.068a.884.884 0 01-.973-1.36L10.56 5.24a1.767 1.767 0 012.465-.41z" fill="currentcolor" fill-rule="evenodd"></path></svg>                    </button>
                  </div>
                  <div className="ml-1 flex items-center text-sm font-bold text-navy-700 dark:text-white"></div>
                  <div className="ml-2 flex items-center text-sm font-normal text-gray-600 dark:text-white"></div>
                </div>
              </div>
            ))}
          </div>
        )}

        <div className="flex items-center justify-between">
          <button
            onClick={() =>
              handleRouteClick(workplaces.length === 0 ? "/auth/workplace" : 0)
            }
            className="linear w-90 m-4 mt-5  mb-5 w-full rounded-lg bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
          >
            {" "}
            {workplaces.length === 0 ? "Add New Workplace" : buttonText}
          </button>
        </div>
      </Card>
    </LoadScript>
  );
};

export default UpdateWorkPlace;
