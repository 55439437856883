import { MdKeyboardArrowRight } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { getLocalStorageValue, fetchRegularDetails } from "utils/utils";
import { MdOutlineArrowBack } from "react-icons/md";
import GenericModal from "../admin/default/components/GenericModal";
import WorkplaceList from "../admin/default/components/WorkplaceList";
import DeleteWorkplaces from "../admin/default/components/DeleteWorkplaces";
import UpdateWorkPlace from "views/admin/default/components/UpdateWorkplace";
import { getAuth, signOut } from "firebase/auth";
import {useEffect, useState} from 'react'
import { toast } from "react-toastify";

export default function Settings({ ...props }) {
  const navigate = useNavigate();

  const handleRouteClick = (page) => {
    navigate(page); // Navigate to specific page
  };



  const [isWorking, setIsWorking] = useState(null);
  const [regularDetails, setRegularDetails] = useState(null);
  const auth = getAuth();
  let userName = null;
  if (auth.currentUser) {
    userName = auth.currentUser.phoneNumber;
  }
  const handleLogout = (page) => {
    signOut(auth).then(() => {
      navigate(page); // Navigate to specific page
      localStorage.clear();
      // console.log("Logging the authentication status", props);
      props.setIsAuthenticated(false);
      document.getElementById("main-body").classList.remove("dark");
    }).catch((error) => {
      console.error("Error during logout:", error);
    });
  };


  useEffect(() => {
    fetchRegularDetails(userName).then(details => {
      setRegularDetails(details);
    });
  }, [isWorking]);

  useEffect(() => {
    const localStorageValue = localStorage.getItem("isWorking") === "true";
    setIsWorking(regularDetails?.isWorking ?? localStorageValue);
  }, [regularDetails]);



  const redirectToCreateEvent = () => {
    if (isWorking) {
      navigate("/auth/create-event");
    } else {
      toast.warning("You are not working");
    }
  };


  return (
    <div className={`0 m-auto flex   h-full w-[350px] max-w-[500px] justify-center px-2 pt-6 sm:w-[350px] lg:mb-10 lg:justify-start ${isWorking ? "" : "!text-white"}  `}>
      {/* Settings Menu */}
      <div className="mt-[0vh] w-full max-w-full flex-col items-center justify-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <button
          onClick={() => handleRouteClick(`/auth/workplace-home`)}
          className={`absolute ${isWorking ? "text-navy-700" : "!text-white"}`}
        >
          <MdOutlineArrowBack className="mt-[4px] ml-[5px] h-8 w-8" />
        </button>
        <h4 className={`align-center mb-[59px] mt-[5px] flex justify-center text-xl font-bold ${isWorking ? "text-navy-700" : "text-white"}`}>
          Settings
        </h4>

        <h4 className={`flex items-center justify-center text-xl ${isWorking ? "text-navy-700" : "!text-white"}`}>
          {getLocalStorageValue("userDetails", "userName") || "User"}
        </h4>
        <div className="mr-1 ml-1 flex-col items-center justify-center">
          <button
            onClick={() => handleRouteClick("/auth/edit-profile")}
            className={`mt-5 flex w-full items-center justify-between text-base ${isWorking ? "text-navy-700" : "!text-white"}`}
          >
            Edit Profile{" "}
            <MdKeyboardArrowRight className="h-6 w-6 self-end text-gray-500" />
          </button>

          <div className="mt-5 h-[1px] w-full border-t border-gray-400"></div>
          <GenericModal
            modalTrigger={
              <button className={`mt-5 flex w-full items-center justify-between text-base ${isWorking ? "text-navy-700" : "!text-white"}`}>
                Add new workplace{" "}
                <MdKeyboardArrowRight className="h-6 w-6 justify-end text-gray-500 " />
              </button>
            }
            modalContent={
              <WorkplaceList
                type="'Find'"
                headerTitle="Find your workplace"
                buttonText="Add workplace"
              />
            }
          />
                    <div className="mt-5 h-[1px] w-full border-t border-gray-400"></div>

          <GenericModal
            modalTrigger={
              <button className={`mt-5 flex w-full items-center justify-between text-base ${isWorking ? "text-navy-700" : "!text-white"}`}>
                Update Current workplace
                <MdKeyboardArrowRight className="h-6 w-6 justify-end text-gray-500 " />
              </button>
            }
            modalContent={
              <UpdateWorkPlace
              type="'Find'"
              headerTitle="Update Current Workspace"
              buttonText="Done"
            />
            }
          />
          <div className="mt-5 h-[1px] w-full border-t border-gray-400"></div>
          <GenericModal
            modalTrigger={
              <button    className={`mt-5 flex w-full items-center justify-between text-base ${isWorking ? "text-navy-700" : "!text-white"}`}
              >
                Delete a workplace{" "}
                <MdKeyboardArrowRight className="h-6 w-6 justify-end text-gray-500 " />
              </button>
            }
            modalContent={
              <DeleteWorkplaces
                type="'Find'"
                headerTitle="Delete a workplace"
                buttonText="Done"
              />
            }
          />

          <div className="mt-5 h-[1px] w-full border-t border-gray-400"></div>
          <GenericModal
            modalTrigger={
              <button
                onClick={() => handleRouteClick("/auth/events")}
                className={`mt-5 flex w-full items-center justify-between text-base ${isWorking ? "text-navy-700" : "!text-white"}`}
              >
                View Events{" "}
                <MdKeyboardArrowRight className="h-6 w-6 justify-end text-gray-500 " />
              </button>
            }
          />

          <div className="mt-5 h-[1px] w-full border-t border-gray-400"></div>
          <GenericModal
            modalTrigger={
              <button
                onClick={redirectToCreateEvent}
                className={`mt-5 flex w-full items-center justify-between text-base ${isWorking ? "text-navy-700" : "!text-white"}`}
              >
                Create An Event{" "}
                <MdKeyboardArrowRight className="h-6 w-6 justify-end text-gray-500 " />
              </button>
            }
          />
          <div className="mt-5 h-[1px] w-full border-t border-gray-400"></div>
          <a
            href="https://1mr7h7lz5wt.typeform.com/to/kp3WjXj3"
            target="_blank"
            rel="noreferrer"
          >
            <button className={`mt-5 flex w-full items-center justify-between text-base ${isWorking ? "text-navy-700" : "!text-white"}`}>
              Share Feedback{" "}
              <MdKeyboardArrowRight className="h-6 w-6 justify-end text-gray-500 " />
            </button>
          </a>
          <div className="mt-5 h-[1px] w-full border-t border-gray-400"></div>
          <a
            href="https://meetgliddy.com/privacypolicy"
                target="_blank"
                rel="noreferrer"
          >
            <button className={`mt-5 flex w-full items-center justify-between text-base ${isWorking ? "text-navy-700" : "!text-white"}`}>
              Privacy Policy{" "}
              <MdKeyboardArrowRight className="h-6 w-6 justify-end text-gray-500 " />
            </button>
          </a>
          <div className="mt-5 h-[1px] w-full border-t border-gray-400"></div>
          <a href="https://meetgliddy.com/terms-of-service" target="_blank">
            <button className={`mt-5 flex w-full items-center justify-between text-base ${isWorking ? "text-navy-700" : "!text-white"}`}>
              Terms and conditions
              <MdKeyboardArrowRight className="h-6 w-6 justify-end text-gray-500 " />
            </button>
          </a>
          <div className="mt-5 h-[1px] w-full border-t border-gray-400"></div>
          <button
            onClick={() => handleLogout("/auth/sign-in")}
            className="mt-5 flex w-full items-center justify-between text-base text-brand-500"
          >
            Log Out
            <MdKeyboardArrowRight className="h-6 w-6 justify-end text-gray-500 " />
          </button>
        </div>
      </div>
    </div>
  );
}
